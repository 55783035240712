














import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OPresenceTile: () =>
      import("@/components/organisms/event/accessControl/o-presence-tile.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      controlPoints: [],
    });

    const fetchControlPoints = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/control-point`)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(({ data: { controlPoints, total } }) => {
          state.controlPoints = controlPoints;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.controlPoints = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchControlPoints);

    return { state };
  },
});
